<template>
  <div class="rightSide">
    <div class="rightSide_inner">
      <div class="rightSide_inner_title">
        <p style="font-size: 30px; color: #333333">优惠券</p>
      </div>

      <div class="table_style">
        <el-table
          ref="multipleTable"
          header-align="center"
          border
          class="main-table"
          :data="tableData"
          tooltip-effect="dark"
          style="width: 100%"
        >
          <el-table-column prop="store_code" label="优惠金额" align="center" />
          <el-table-column prop="file_url" label="使用门槛" align="center" />
          <el-table-column
            prop="extensions"
            label="优惠券名称"
            align="center"
          />
          <el-table-column prop="create_time" label="说明文字" align="center" />
          <el-table-column prop="create_time" label="领取时间" align="center" />
          <el-table-column prop="create_time" label="过期时间" align="center" />
          <el-table-column
            prop="create_time"
            label="优惠券编码"
            align="center"
          />
          <el-table-column prop="create_time" label="指定产品" align="center" />
          <el-table-column prop="create_time" label="使用时间" align="center" />
        </el-table>
      </div>

      <div class="footer">
        <div>
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="currentPage4"
            :page-size="100"
            layout="total,  prev, pager, next, jumper"
            :total="0"
            background
          >
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  data() {
    return {
      tableData: [],
      currentPage4: 0,
    };
  },
  methods: {
    handleSizeChange(val) {},
    handleCurrentChange(val) {},
  },
};
</script>
<style lang="scss" scoped>
.rightSide {
  width: 1320px;
  height: 786px;
  margin: 15px 0 0 15px;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  &_inner {
    width: 1218px;
    height: 714px;
    &_title {
      width: 1218px;
      height: 68px;
    }
  }
}
.table_style {
  width: 1218px;
  height: 68px;
  margin-top: 30px;
}
.footer {
  margin-top: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
<style lang="scss">
.footer .el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: #218da0; //修改后的背景图颜色
  color: #fff;
}
</style>
